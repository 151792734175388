import { useCallback } from 'react';

import { isMobile } from '@common/device-manager';
import { useIsToss } from '@common/device-manager';
import { useTracker } from '@common/log-manager';
import { useAirbridgeDeeplinks, WEB_TO_APP_DEEP_LINK } from '@common/marketing-tracker';
import { useBenefitLandingPopup } from '@common/overlay-manager';

import { useAppBannerVisibility } from './useAppInstallBannerVisibility';

const ZIGZAG_ICON_URL = 'https://cf.image-farm.s.zigzag.kr/original/cms/2025/01/09/202501090519309476_093291.png';

export function useLandingBannerService() {
  const isToss = useIsToss();
  const tracker = useTracker();
  const { is_app_install_banner_hidden: hide } = useAppBannerVisibility();
  const { airbridgeDeeplinks, buildDeeplink } = useAirbridgeDeeplinks();
  const { open } = useBenefitLandingPopup();
  const deeplink = buildDeeplink({
    page_scheme: 'main',
    data: {
      channel: 'affiliate_promotion',
      campaign: 'toss_x_zigzag',
    },
  });

  const sendLog = useCallback(() => {
    tracker.sendImmediately({
      category: 'click',
      navigation: 'global_navigation_page',
      object_type: 'banner',
      object_id: 'app_download',
    });
  }, []);

  const clickLandingBanner = useCallback(() => {
    if (isMobile()) {
      airbridgeDeeplinks({
        deeplink: isToss ? deeplink : WEB_TO_APP_DEEP_LINK.HOME,
        ctaParams: { cta_param_1: isToss ? 'toss_x_zigzag' : 'kr_topbanner' },
      });
    } else {
      open();
    }
  }, [airbridgeDeeplinks, open]);

  return { clickLandingBanner, hide, bannerImage: ZIGZAG_ICON_URL, sendLog };
}
