import { vars } from '@croquiscom-pvt/zds/tokens';
import { css } from '@emotion/react';

import { CSSValue, CSSValueWithPX } from './css-utils';

export const backgroundImage = (img_urls: { size1x: string; size2x?: string; size3x?: string }) => {
  const size1x = img_urls.size1x;
  const size2x = img_urls.size2x || img_urls.size1x;
  const size3x = img_urls.size3x || img_urls.size2x || img_urls.size1x;
  return css`
    background-repeat: no-repeat;
    background-color: transparent;
    background-image: url(${size1x});
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
      background-image: url(${size2x});
    }
    @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {
      background-image: url(${size3x});
    }
  `;
};

export const imageSize = (width: CSSValue, height: CSSValue) => css`
  width: ${CSSValueWithPX(width)};
  height: ${CSSValueWithPX(height)};
  background-size: ${CSSValueWithPX(width)} ${CSSValueWithPX(height)};
`;

export const safeAreaInsetBottomHeight = (value: CSSValue, filledHeight: CSSValue = 0) => css`
  height: ${CSSValueWithPX(value)};
  height: calc(${CSSValueWithPX(value)} - (constant(safe-area-inset-bottom) + ${CSSValueWithPX(filledHeight)}));
  height: calc(${CSSValueWithPX(value)} - (env(safe-area-inset-bottom) + ${CSSValueWithPX(filledHeight)}));
`;

export const safeAreaInsetBottom = (value: CSSValue = 0) => css`
  padding-bottom: ${CSSValueWithPX(value)};
  padding-bottom: calc(constant(safe-area-inset-bottom) + ${CSSValueWithPX(value)});
  padding-bottom: calc(env(safe-area-inset-bottom) + ${CSSValueWithPX(value)});
`;
export const safeAreaInsetTop = (value: CSSValue = 0) => css`
  padding-top: ${CSSValueWithPX(value)};
  padding-top: calc(var(--inset-top, constant(safe-area-inset-top)) + ${CSSValueWithPX(value)});
  padding-top: calc(var(--inset-top, env(safe-area-inset-top)) + ${CSSValueWithPX(value)});
`;

export const resetButton = css`
  background: none;
  border: 0;
  cursor: pointer;
  appearance: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &[disabled] {
    cursor: default;
  }
`;

export const invisibleScrollBar = css`
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const touchStyle = css`
  transition: all 100ms linear;
  border-radius: ${vars.cornerRadius[8]};

  appearance: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  padding: 6px;
  width: 44px;
  height: 44px;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: ${vars.color.buttonSurfaceHovered};
    }
  }
  &:active {
    background-color: ${vars.color.buttonSurfacePressed};
  }
`;
