import { createContext, FC, useContext, useEffect, useState } from 'react';
import DefaultRouter from 'next/router';

import { getConfigValue } from '@common/config';
import { useZigzag } from '@common/device-manager';
import { getSiteIdFromHost } from '@common/site-manager';

import { getTossUserNoValue } from './custom_log';

export function isInApp(user_agent: string) {
  if (typeof window !== 'undefined') {
    if ((window as any).zigzag) {
      return true; // Android
    }
    if ((window as any).webkit?.messageHandlers?.zigzag?.postMessage) {
      return true; // iOS
    }
  }
  return user_agent.includes('ZigZag/');
}

export interface Log {
  category:
    | 'tti'
    | 'pageview'
    | 'click'
    | 'impression'
    | 'save_product'
    | 'remove_saved_product'
    | 'add_bookmark'
    | 'remove_bookmark'
    | 'add_to_cart'
    | 'remove_from_cart'
    | 'deeplink'
    | 'complete_zpay_purchase';
  navigation: string;
  navigation_sub?: Record<string, any>;
  object_section?: string;
  object_type?: string;
  object_id?: string;
  object_idx?: number;
  object_url?: string;
  data?: Record<string, any>;
  url?: string;
}

function getPageviewLog(path: string): Log | null {
  try {
    const url = path.startsWith('/') ? window.location.origin + path : path;

    const { pathname, search, searchParams } = new URL(url);
    const params = search ? (Object as any).fromEntries?.(searchParams) : undefined;

    // 로그인 메인
    if (pathname === '/auth/login') {
      return { url, category: 'pageview', navigation: 'login', navigation_sub: { url } };
    }

    // 이메일 로그인
    if (pathname === '/auth/email-login') {
      return { url, category: 'pageview', navigation: 'login_email', navigation_sub: { url } };
    }

    // 약관 동의
    if (pathname === '/auth/terms-agreement') {
      return { url, category: 'pageview', navigation: 'terms_agreement', navigation_sub: { url } };
    }

    // 모바일 인증
    if (pathname === '/auth/mobile-authentication') {
      return { url, category: 'pageview', navigation: 'tel_authorization', navigation_sub: { url } };
    }

    // 이메일, 비밀번호 입력
    if (pathname === '/auth/write-email') {
      return !params
        ? { url, category: 'pageview', navigation: 'email_signup_form', navigation_sub: { url } }
        : { url, category: 'pageview', navigation: 'enter_email', navigation_sub: { url } };
    }

    // 계정 연결
    if (pathname === '/auth/connect-account') {
      return { url, category: 'pageview', navigation: 'connect_account', navigation_sub: { url } };
    }

    // 소셜 연동 안내
    if (pathname === '/auth/duplicate-social-account') {
      return { url, category: 'pageview', navigation: 'duplicated_social_account', navigation_sub: { url } };
    }

    // 내 정보
    if (pathname === '/auth/profile') {
      return { url, category: 'pageview', navigation: 'my_profile', navigation_sub: { url } };
    }
  } catch (error) {
    return null;
  }

  return null;
}

export class Tracker {
  logs: Log[] = [];
  timer_id: NodeJS.Timeout | undefined;
  in_app: boolean;
  private static instance: Tracker;

  constructor(
    readonly fake: boolean,
    readonly user_agent: string,
  ) {
    this.fake = fake;
    this.in_app = isInApp(user_agent);
    if (!fake && !this.in_app) {
      // UUID 발급을 위해 빈 요청을 하나 한다
      this._send({ last: true });

      if (typeof window !== 'undefined') {
        window.addEventListener('beforeunload', () => {
          this.send(true);
        });
      }
    }
  }

  trackPageview(path: string) {
    if (this.fake) {
      console.log('something wrong');
      return;
    }

    const log = getPageviewLog(path);
    log && this.addLog(log);
  }

  addLog(base_log: Log) {
    const toss_user_no = getTossUserNoValue();
    const log = {
      ...base_log,
      client_access_time: Date.now(),
    };

    // MEMO: 토스 사용자 트래킹을 위한 임시 추가 (전역 대응 필요)
    if (toss_user_no) {
      log.data = log.data ?? {};
      log.data.toss_user_no = toss_user_no;
    }

    if (this.in_app) {
      return;
    }

    this.logs.push(log);

    // 로그가 어느 정도 쌓인 경우 바로 보내고, 아니면 조금 모아서 보낸다
    if (this.timer_id) {
      clearTimeout(this.timer_id);
    }
    const wait = this.logs.length > 10 ? 1 : 5;
    this.timer_id = setTimeout(() => {
      this.send(false);
    }, wait * 1000);
  }

  send(last: boolean) {
    if (this.fake) {
      console.log('something wrong');
      return;
    }
    if (this.timer_id) {
      clearTimeout(this.timer_id);
    }
    this.timer_id = undefined;
    if (!last && this.logs.length === 0) {
      return;
    }
    const data: any = {
      last,
      logs: this.logs,
    };
    this.logs = [];
    this._send(data);
  }

  sendImmediately(log: Log) {
    const toss_user_no = getTossUserNoValue();
    const base_log = {
      ...log,
    };

    // MEMO: 토스 사용자 트래킹을 위한 임시 추가 (전역 대응 필요)
    if (toss_user_no) {
      base_log.data = log.data ?? {};
      base_log.data.toss_user_no = toss_user_no;
    }

    if (this.fake) {
      console.log('something wrong');
      return;
    }
    const data: any = {
      last: false,
      logs: [
        {
          ...base_log,
          client_access_time: Date.now(),
        },
      ],
    };
    return this._send(data);
  }

  async _send(data: any) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (navigator.sendBeacon != null) {
      try {
        const blob = new Blob([JSON.stringify(data)]);
        const ab = await blob.arrayBuffer();
        const dv = new DataView(ab);
        for (let i = 0; i < ab.byteLength; i++) {
          const byte = dv.getUint8(i);
          if (byte >= 128) {
            dv.setUint8(i, byte - 128);
          } else {
            dv.setUint8(i, byte + 128);
          }
        }

        const site_id = getSiteIdFromHost();
        const api_consumer_base_url = getConfigValue('apiConsumerBaseUrl', site_id);

        navigator.sendBeacon(
          api_consumer_base_url +
            `/tr?fake=${this.fake}&ua=${this.user_agent}&na=${navigator.userAgent}&ia=${this.in_app}&last=${data.last}&ll=${data.logs?.length}`,
          ab,
        );
      } catch (error) {
        // ignore error
      }
    }
  }
}

const noop = () => {
  return;
};

const disabledTracker = {
  fake: true,
  trackPageview: noop,
  addLog: noop,
  send: noop,
  sendImmediately: noop,
} as unknown as Tracker;

const DisabledTrackerContext = createContext<Tracker>(disabledTracker);

const TrackerContext = createContext<Tracker>(new Tracker(true, ''));
TrackerContext.displayName = 'TrackerContext';

export function useTracker() {
  const is_in_app = useZigzag();
  return useContext(is_in_app ? DisabledTrackerContext : TrackerContext);
}

const isApp = (user_agent: string) => /(zigzag)/i.test(user_agent);

interface TrackerProviderProps {
  user_agent: string;
  disabled?: boolean; // 추적 여부를 끌 수 있는 optional prop
}

export const TrackerProvider: FC<TrackerProviderProps> = (props) => {
  const { user_agent, disabled = false, children } = props;
  const is_in_app = isApp(user_agent);
  const is_fake = typeof navigator === 'undefined';
  const [tracker] = useState(() => {
    if (disabled || is_in_app) {
      return disabledTracker;
    }
    return new Tracker(is_fake, user_agent);
  });
  useEffect(() => {
    if (disabled) return;

    tracker.trackPageview(DefaultRouter.asPath);
    const handleRouteChange = (url: string, _: { shallow: boolean }) => {
      tracker.trackPageview(url);
    };
    DefaultRouter.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      DefaultRouter.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [tracker, disabled]);

  return <TrackerContext.Provider value={tracker}>{children}</TrackerContext.Provider>;
};

/**
 * 동기식으로 uuid 1회 발급을 위해 호출하는 API
 */
export async function retrieveUuidSynchronously() {
  const data = { last: true };
  const blob = new Blob([JSON.stringify(data)]);
  const ab = await blob.arrayBuffer();
  const dv = new DataView(ab);
  for (let i = 0; i < ab.byteLength; i++) {
    const byte = dv.getUint8(i);
    if (byte >= 128) {
      dv.setUint8(i, byte - 128);
    } else {
      dv.setUint8(i, byte + 128);
    }
  }

  const site_id = getSiteIdFromHost();
  const api_consumer_base_url = getConfigValue('apiConsumerBaseUrl', site_id);

  const url =
    api_consumer_base_url +
    `/tr?fake=true` +
    `&ua=${navigator.userAgent}` +
    `&na=${navigator.userAgent}` +
    `&ia=false` +
    `&last=${data.last}`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      body: ab,
    });

    if (!response.ok) {
      throw new Error(`서버 응답이 실패했습니다: ${response.status}`);
    }

    const result = await response.text();
  } catch (error) {
    console.log(error);
  }
}
