declare global {
  interface Window {
    ENP_VAR: { collect: any; conversion: { product: any[]; ordCode?: string; totalPrice?: string; totalQty?: string } };
    CallMtm({
      productName,
      convType,
      click,
    }?: {
      productName?: string;
      convType?: 'etc' | 'join' | 'login';
      click?: string;
    }): void;
  }
}
export const initEnlipleScript = () => {
  const enlipleWindow = window as any;

  enlipleWindow.CallMtm =
    enlipleWindow.CallMtm ||
    function () {
      // eslint-disable-next-line prefer-rest-params
      (enlipleWindow.CallMtm.q = enlipleWindow.CallMtm.q || []).push(arguments);
    };
};
