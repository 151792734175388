import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { initEnlipleScript } from './enlipleScript';

export const usePageLoadMobonScript = ({ skip_call_mtm }: { skip_call_mtm: boolean }) => {
  const router = useRouter();

  useEffect(() => {
    initEnlipleScript();
    if (!skip_call_mtm) {
      window.CallMtm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);
};
