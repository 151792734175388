import merge from 'lodash/merge';

import { UxGoodsCardItemForVerticalProductCard } from '../vertical-product-card/types';

// 병합 순서: item.log -> item.ubl.server_log -> drLogMap
export function getServerLogForVerticalProductCard(
  catalogProductId: string | null,
  item: UxGoodsCardItemForVerticalProductCard,
  drLogMap: Record<string, string> | null = null,
) {
  let server_log: Record<string, unknown> = {};

  if (item.log && typeof item.log === 'string') {
    server_log = parseQueryString(item.log);
    // item.log의 dr_log는 JSON Strigify가 되어 있기 때문에 JSON.parse 처리를 해줘야 합니다.
    try {
      if (server_log.dr_log && typeof server_log.dr_log === 'string') {
        server_log.dr_log = JSON.parse(server_log.dr_log);
      }
    } catch (error) {
      console.error(error);
    }
  }

  if (item.ubl?.server_log && typeof item.ubl.server_log === 'string') {
    try {
      server_log = merge(JSON.parse(item.ubl.server_log), server_log);
    } catch (error) {
      console.error(error);
    }
  }

  /*
    drLogMap이 있을 경우 dr_log를 server_log에 merge합니다.
  */
  try {
    let dr_log: Record<string, unknown> | null = null;

    if (catalogProductId && drLogMap) {
      dr_log = JSON.parse(drLogMap[catalogProductId]);
      server_log.dr_log = merge(dr_log, server_log.dr_log);
    }
  } catch (error) {
    console.error(error);
  }

  return server_log;
}

const parseQueryString = <T extends Record<string, unknown>>(query: string) => {
  return query
    .replace('?', '')
    .split('&')
    .map((entry) => {
      const [key, value] = entry.split('=');
      return { key, value };
    })
    .reduce((acc, { key, value }) => {
      // typescript 한계로 에러가 남
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      acc[key] = value;
      return acc;
    }, {} as T);
};
