import { gql_request, GqlRequestOptions } from '@common/api-request';

import * as types from './user-account_types.2';

export function getUserName(variable?: null, options?: GqlRequestOptions) {
  const query = 'fragment UserAccountNamePart on UserAccount { full_name authenticated_name uuid } query GetUserName { user_account { ...UserAccountNamePart } }';
  return gql_request<types.GetUserName, null>('GetUserName', query, variable, { use_consumer: true, ...options });
}

export function getUserAccountKakaoConnected(variable?: null, options?: GqlRequestOptions) {
  const query = 'fragment UserAccountKakaoConnectedPart on UserAccount { kakao_connected } query GetUserAccountKakaoConnected { user_account { ...UserAccountKakaoConnectedPart } }';
  return gql_request<types.GetUserAccountKakaoConnected, null>('GetUserAccountKakaoConnected', query, variable, { use_consumer: true, ...options });
}

export function getUserUUID(variable?: null, options?: GqlRequestOptions) {
  const query = 'query GetUserUUID { user { uuid } }';
  return gql_request<types.GetUserUUID, null>('GetUserUUID', query, variable, { use_consumer: true, ...options });
}

export function getIsLoggedIn(variable?: null, options?: GqlRequestOptions) {
  const query = 'query GetIsLoggedIn { is_logged_in: isLoggedIn }';
  return gql_request<types.GetIsLoggedIn, null>('GetIsLoggedIn', query, variable, { use_consumer: true, ...options });
}
