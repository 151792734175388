import { convertToSupertossUrl } from '@widgets/web-landing-banner';

declare const window: Window & {
  __TOSS_API__: {
    inAppLogin: (options?: { isSignUp: boolean }) => void;
  };
};

export const loginToss = async () => {
  try {
    if (!window.__TOSS_API__?.inAppLogin) {
      location.assign(
        convertToSupertossUrl(
          location.origin +
            `/auth/login-toss?forceLogin=true&redirect=${encodeURIComponent(location.origin + '/auth/login-toss?autoClose=true')}`,
        ),
      );
      return;
    }

    window.__TOSS_API__?.inAppLogin({ isSignUp: true });
  } catch (error) {
    console.error(error);
  }
};
