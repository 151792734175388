import styled from '@emotion/styled';

import { LandingBanner } from '../components/LandingBanner';
import { useLandingBannerService } from '../services/useLandingBannerService';

export const LandingBannerContainer = () => {
  const { clickLandingBanner, hide, bannerImage, sendLog } = useLandingBannerService();

  if (hide) {
    return null;
  }

  return (
    <Wrapper>
      <LandingBanner
        imageSrc={bannerImage}
        imageAlt='landing-banner'
        buttonText='앱으로 이동'
        onClickBanner={() => {
          clickLandingBanner();
          sendLog();
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
`;
