/* eslint @nx/enforce-module-boundaries: 'warn' */
import { css } from '@emotion/react';

import { apple_store_link, play_store_link, web_path } from '@common/constants';
import { isAndroid, isIOS, isMobile } from '@common/device-manager';
import { Photo, PopupDialogOption, TransPlainText } from '@common/toolbox';

import { BottomDialogOption } from '../dump/BottomDialog';
import { useBottomDialog } from '../useBottomDialog';
import { usePopupDialog } from '../usePopupDialog';
export const SIGN_OUT_LANDING_LINK = 'https://zigzag.onelink.me/4107287618/b371c97f';
export const HOME_LANDING_LINK_FORM_WEB_HOME = {
  KR: 'https://zigzag.onelink.me/4107287618/hxzdlxd7',
};

export const HOME_LANDING_LINK = {
  KR: 'https://zigzag.onelink.me/4107287618/52b4c05c',
  JP: 'https://zigzag.onelink.me/Rhv9/d2khpna4',
  US: 'https://zigzag.onelink.me/cQuc/u8r9xyq4',
  CA: 'https://zigzag.onelink.me/cQuc/f0fue85i',
};

const LOGO_ICON = 'https://cf.image-farm.s.zigzag.kr/original/cms/2025/02/04/202502040136408499_067950.png';
const QR_IMAGE = 'https://cf.image-farm.s.zigzag.kr/original/cms/2025/02/04/202502042314338100_027517.png';
const WELCOME_IMAGE = 'https://cf.image-farm.s.zigzag.kr/original/cms/2025/02/06/202502060150293223_012126.png';

const defaultProps = (landing_link = HOME_LANDING_LINK.KR) => {
  return {
    showCloseButton: true,
    wrapperStyle: css`
      min-width: 260px;
      max-width: 260px;
      padding-top: 40px;
      padding-bottom: 40px;
      box-shadow: none;
    `,
    body_padding: '14px 16px 0 16px',
    image: <Photo src={LOGO_ICON} width={80} height={80} objectfit='cover' />,
    title: <TransPlainText ns='common' i18nKey={'해당 기능은\n앱에서 사용 가능해요!'} />,
    text: <TransPlainText ns='common' i18nKey='지그재그 앱에서 확인해보세요.' />,
    buttons: [
      {
        children: <TransPlainText ns='common' i18nKey='앱에서 보기' />,
        onClick: () => {
          window.open(landing_link, '_blank');
        },
        style: {
          width: 'fit-content',
        },
      },
    ],
  };
};

export const MobileLandingPopup = (landing_link = HOME_LANDING_LINK.KR): PopupDialogOption => ({
  ...defaultProps(landing_link),
});

export const DeskTopLandingPopup = (): PopupDialogOption => ({
  ...defaultProps(),
  buttons: [{ children: <TransPlainText ns='common' i18nKey='확인' /> }],
});

export const useLandingPopup = (landing_link?: string) => {
  const { open: openPopup, close } = usePopupDialog();

  return {
    open: () => openPopup(isMobile() ? MobileLandingPopup(landing_link) : QrLandingPopup()),
    close,
  };
};
export const QrLandingPopup = (): PopupDialogOption => ({
  ...defaultProps(),
  image: <Photo src={QR_IMAGE} width={64} height={64} objectfit='cover' />,
});

export const MobileBenefitLandingPopup = (landing_link = HOME_LANDING_LINK.KR): PopupDialogOption => ({
  ...defaultProps(landing_link),
});

export const useBenefitLandingPopup = () => {
  const { open: openPopup, close } = usePopupDialog();

  return {
    open: () => openPopup(isMobile() ? BookmarkCouponPopup() : QrLandingPopup()),
    close,
  };
};
export const SignUpPopup = (message: string | null): PopupDialogOption => ({
  ...defaultProps(),
  body_padding: '2px 16px 0 16px',
  image: <Photo src={WELCOME_IMAGE} width={180} height={88} />,
  title: <TransPlainText ns='auth' i18nKey='지그재그 가입을 축하드려요' />,
  text: message ?? <TransPlainText ns='auth' i18nKey='지그재그에서 편리한 쇼핑하세요!' />,
  canBackdropResolve: true,
  buttons: [
    {
      children: '확인',
      style: {
        width: 'fit-content',
      },
    },
  ],
});

export const BookmarkCouponPopup = (landing_link = HOME_LANDING_LINK.KR): PopupDialogOption => ({
  ...defaultProps(landing_link),
  title: '즐겨찾기 쿠폰은\n앱에서 다운받을 수 있어요!',
  text: '지그재그 앱에서 확인해보세요.',
});

export const ReviewPopup = (landing_link = HOME_LANDING_LINK.KR): PopupDialogOption => ({
  ...defaultProps(landing_link),
  title: '리뷰 작성은\n앱에서 할 수 있어요!',
  text: '지그재그 앱에서 확인해보세요.',
});

export const LatestAppVersionPopup = (): PopupDialogOption => {
  return {
    ...defaultProps(),
    title: <TransPlainText ns='products' i18nKey='앱을 업데이트 해주세요.' />,
    text: <TransPlainText ns='products' i18nKey='이전 버전에서는 이용하실 수 없습니다.' />,
    buttons: [
      {
        children: <TransPlainText ns='common' i18nKey='업데이트 하기' />,
        onClick: () => {
          if (isIOS()) {
            window.location.assign(apple_store_link);
          } else if (isAndroid()) {
            window.location.assign(play_store_link);
          }
        },
      },
    ],
  };
};

const InvalidSignUpDialog = (): BottomDialogOption => ({
  ...defaultProps(),
  image: null,
  title: '잘못된 접근입니다',
  text: '불편을 드려 죄송합니다',
  buttons: [
    {
      children: <TransPlainText ns='common' i18nKey='확인' />,
      onClick: () => {
        window.location.assign(web_path.auth.login);
      },
      style: {
        width: 'fit-content',
      },
    },
  ],
  disableBackdropClick: true,
});

export const useInvalidSignUpDialog = () => {
  const { open: openBottomDialog, close } = useBottomDialog();
  return {
    open: async () => {
      await openBottomDialog(InvalidSignUpDialog());
    },
    close,
  };
};

export const KrPromotionEventQrLandingPopup = (): PopupDialogOption => {
  const krPromotionLayoutConcernedStyle = css`
    z-index: 1000;
  `;
  return {
    ...defaultProps(),
    body_padding: '22px 16px 0 16px',
    image: <Photo src={QR_IMAGE} width={64} height={64} objectfit='cover' />,
    title: <TransPlainText ns='common' i18nKey={'지그재그 앱에서 더 많은\n혜택을 받아보세요!'} />,
    text: <TransPlainText ns='common' i18nKey={'스마트폰으로 카메라 앱을 켜서\nQR코드를 포커스해보세요.'} />,
    canBackdropResolve: true,
    containerStyle: krPromotionLayoutConcernedStyle,
  };
};

export const KrPromotionPdpQrLandingPopup = (): PopupDialogOption => {
  const krPromotionLayoutConcernedStyle = css`
    z-index: 1000;
  `;
  return {
    ...defaultProps(),
    body_padding: '22px 16px 0 16px',
    image: <Photo src={QR_IMAGE} width={64} height={64} objectfit='cover' />,
    title: <TransPlainText ns='common' i18nKey={'지그재그 앱에서 더 많은\n혜택을 받아보세요!'} />,
    text: <TransPlainText ns='common' i18nKey={'스마트폰으로 카메라 앱을 켜서\nQR코드를 포커스해보세요.'} />,
    canBackdropResolve: true,
    containerStyle: krPromotionLayoutConcernedStyle,
  };
};
