import { getRuntimeConfigValue } from '@common/next-runtime-config';
import { getSiteIdFromHost } from '@common/site-manager';

/*
 - 'TEAM_{조직명}'(팀, 파트 등등) 으로 지정하여 사용
 - grafana에서 필터하는 용도로 message에 타 조직명 포함되는 케이스 대비하여 'TEAM_' 포함하여 정의
*/
export enum Team {
  TEAM_COC = 'TEAM_COC',
  TEAM_DNCFE = 'TEAM_DNCFE',
}

/**
 * bifrost에 custom-log 전송
 * @param message 로그 메시지
 * @param team TEAM_{조직명}
 */
export const sendCustomLog = async (message: string, team: Team) => {
  try {
    const site_id = getSiteIdFromHost();
    const bifrost_url = getRuntimeConfigValue('apiConsumerBaseUrl', site_id);

    await fetch(`${bifrost_url}/custom-log?site_id=${site_id}&team=${team}&message=${encodeURIComponent(message)}`);
  } catch (e) {
    console.error(e);
  }
};

/**
 * MEMO:
 * URL query parameters에서 TossUserNo 값을 가져오거나
 * userAgent에서 TossUserNo 값을 가져오는 함수
 *
 * 토스 3탭 메인에서는 custom ua 주입이 불가해서 qs로 받아올 수 밖에 없는 구조라 더블체크한다.
 * @returns {string|null} tossUserNo 값 또는 null
 */
export function getTossUserNoValue(): string | null {
  try {
    let value: string | null = null;

    // 1. Query Parameter에서 tossUser 값을 찾는다.
    if (typeof window !== 'undefined' && window.location && window.location.search) {
      const params = new URLSearchParams(window.location.search);
      if (params.has('tossUser')) {
        value = params.get('tossUser');
      }
    }

    // 2. Query Parameter에서 못 찾았으면 userAgent에서 찾는다.
    if (!value && typeof navigator !== 'undefined' && navigator.userAgent) {
      const match = navigator.userAgent.match(/TossUser\/([^\s]+)/);
      if (match && match[1]) {
        value = match[1];
      }
    }

    return value;
  } catch {
    return null;
  }
}
