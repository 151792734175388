import { ReactNode } from 'react';
import { ProductCard, ProductCardProps } from '@croquiscom-pvt/zds';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import clsx from 'clsx';

import { useThumbnail, UseThumbnailOptions } from '../hooks/useThumbnail';

export interface ThumbnailProps extends UseThumbnailOptions, Pick<ProductCardProps, 'size'> {
  className?: string;
  overlayImageUrl?: string;
  children: ReactNode;
}

const OverlayImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const productCardThumbnailCss = css`
  @media (hover: hover) and (pointer: fine) {
    .product-card-root & > div > div > img {
      transition: all 100ms linear;
    }
    .product-card-root:hover & > div > div > img {
      transform: scale(1.1);
    }
  }
`;

export const Thumbnail = (props: ThumbnailProps) => {
  const { size, className, imageUrl, webpImageUrl, overlayImageUrl, width, ratio, children } = props;

  const { thumbnailProps } = useThumbnail({
    imageUrl,
    webpImageUrl,
    width,
    ratio,
  });

  return (
    <ProductCard.Thumbnail css={productCardThumbnailCss} className={clsx([size, className])} {...thumbnailProps}>
      {children}
      {overlayImageUrl ? <OverlayImage src={overlayImageUrl} alt='' /> : null}
    </ProductCard.Thumbnail>
  );
};
