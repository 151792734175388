import { ReactElement, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider, ZdsTheme } from '@croquiscom/zds';
import { isIOS } from '@croquiscom-pvt/web2app';
import { Global } from '@emotion/react';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/nextjs';
import { CurrencyMeta } from 'api2/types';
import { useClearAuthInfo } from 'components/auth/hooks/sign-up';
import Stage from 'components/toolbox/stage';
import { AppPropsContext, PageProps } from 'context/AppContext';
import { useBrowserTheme } from 'hooks/useBrowserTheme';
import { useResizeViewHeight } from 'hooks/useResizeViewHeight';
import { i18NextOptionForWebsite } from 'i18n/i18NextOptionForWebsite';
import { useTrackingPageView } from 'log/web-events/hooks/useTrackingPageView';
import { NextPage } from 'next';
import { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import { Router, useRouter } from 'next/router';
import { appWithTranslation } from 'next-i18next';
import { DefaultSeo, SocialProfileJsonLd } from 'next-seo';
import { onlyWebStyle } from 'styles/onlyWebStyle';

import { AppConfigProvider } from '@common/app-config';
import { getZigzagUuidFromRequestHeadersCookie } from '@common/app-config/server';
import { web_path } from '@common/constants';
import { useIsToss, UserAgentProvider } from '@common/device-manager';
import {
  DEFAULT_CURRENCY_META as currency_meta,
  DEFAULT_USER_LOCALE as user_locale,
  I18nProvider,
  UserLocale,
} from '@common/i18n';
import { brazeStyle, ZigzagWebMarketingScript } from '@common/marketing-tracker';
import { convertToTossWebPDPLink, convertToWebPDPLink } from '@common/pdp';
import { SiteId } from '@common/site-manager';
import { font, reset } from '@common/styles';
import { ThemeProvider as NewZDSThemeProvider } from '@common/styles/theme-provider';
import { Preconnect } from '@common/toolbox';
import { LikeProductProvider, LikeSubject, useLikeObserve } from '@widgets/like-product';
import { appendMessageQueryFromPath } from '@widgets/messenger';
import { ProductCardProvider, VerticalProductCardContextType } from '@widgets/product-card';
import { LandingBannerContainer } from '@widgets/web-landing-banner';

import { APP_DEFAULT_SEO } from '../next-seo.config';

import { TrackerProvider } from 'util/Tracker';

import '@common/carousel/swiper/styles.css';
import 'styles/event-modules.css';
import 'styles/zds.css';
import 'react-day-picker/style.css';

export type NextPageAddon<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
  getSentrySendData?: (props: P) => {};
};

export type AppPropsWithLayout = AppProps & {
  Component: NextPageAddon;
  pageProps: PageProps;
};

function DefaultLayout({ children }: { children: React.ReactNode }) {
  return (
    <>
      <LandingBannerContainer />
      {children}
    </>
  );
}

const AppContent = ({ Component, pageProps }: { Component: NextPageAddon; pageProps: PageProps }) => {
  const router = useRouter();
  const is_toss = useIsToss();
  const getLayout = Component.getLayout ?? ((page) => <DefaultLayout>{page}</DefaultLayout>);
  const user_locale: UserLocale = pageProps.user_locale;
  const currency_meta: CurrencyMeta = pageProps.currency_meta;
  const [queryClient] = useState(() => new QueryClient());
  const handlePageLoad = useRef(() => {
    document.body.className += 'active';

    Sentry.configureScope((scope) => {
      scope.addEventProcessor((event) => {
        event.extra = {
          ...(Component.getSentrySendData && { ...Component.getSentrySendData(pageProps) }),
          user_locale,
        };

        return event;
      });
    });
  });

  // 현재는 가로형, 세로형 링크의 동작이 같기 때문에 한벌로 쓰고, 이후 Vertical, Horizontal로 분기가 될 수 있습니다.
  const productCardContextValue = useMemo<VerticalProductCardContextType>(() => {
    return {
      href: (item) =>
        is_toss
          ? convertToTossWebPDPLink({ product_url: item.productUrl })
          : convertToWebPDPLink({ product_url: item.productUrl }),
    };
  }, [is_toss]);

  useClearAuthInfo();
  useResizeViewHeight();
  const { theme } = useBrowserTheme();

  useTrackingPageView();

  useEffect(() => {
    handlePageLoad.current();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * 토스에서 전달받은 safeAreaTop 값을 이용하여 안전영역 padding-top을 설정합니다.
   * iOS에서 safeAreaTop가 간헐적으로 0으로 들어오는 이슈가 있어서 47px 기본값으로 설정(임시)
   */
  useEffect(() => {
    if (router.isReady) {
      const { safeAreaTop } = router.query;
      if (safeAreaTop && !isNaN(Number(safeAreaTop))) {
        const topValue = safeAreaTop === '0' && isIOS() ? '47' : safeAreaTop;

        // 쿼리 파라미터 값이 있으면 덮어쓰기
        document.documentElement.style.setProperty('--safe-area-top', `${topValue}px`);
      }
    }
  }, [router.isReady, router.query]);

  return (
    <ProductCardProvider
      useLikeObserver={useLikeObserve}
      verticalProductCard={productCardContextValue}
      horizontalProductCard={productCardContextValue}
    >
      <LikeProductProvider
        loginUrl={() =>
          appendMessageQueryFromPath(
            `${web_path.auth.login}?redirect=${window.location.origin + window.location.pathname}`,
            'availablePostLogin',
          )
        }
      >
        <LikeSubject>
          <AppPropsContext.Provider value={pageProps}>
            <I18nProvider user_locale={user_locale} currency_meta={currency_meta}>
              <ThemeProvider name={theme as ZdsTheme['name']}>
                <NewZDSThemeProvider colorScheme='light'>
                  <QueryClientProvider client={queryClient}>
                    <div className='safe-area-padding' />
                    <Wrapper>{getLayout(<Component {...pageProps} />)}</Wrapper>
                    <Stage />
                  </QueryClientProvider>
                </NewZDSThemeProvider>
              </ThemeProvider>
            </I18nProvider>
          </AppPropsContext.Provider>
        </LikeSubject>
      </LikeProductProvider>
    </ProductCardProvider>
  );
};
export const MyApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  const contentSecurityNonce = pageProps.security_nonce as string;
  const router = useRouter();
  // 서버에서 user-agent는 CloudFront에 의해 오염된 상태여서 클라이언트에서는 클라이언트 것을 사용한다
  const user_agent: string = (typeof navigator === 'undefined' ? pageProps.user_agent : navigator.userAgent) ?? '';
  const disableTracking = router.asPath.startsWith('/redirect'); // MEMO: 간편로그인 시 동기식으로 무조건 1회 호출해야되는 조건으로 인해 리디렉션 페이지에서의 비동기 호출 제거

  return (
    <AppConfigProvider zigzagUuid={pageProps.ZIGZAGUUID} isZigzagApp={false}>
      <UserAgentProvider initialUserAgent={pageProps.user_agent}>
        <TrackerProvider disabled={disableTracking} user_agent={user_agent}>
          <Sentry.ErrorBoundary>
            <DefaultSeo {...APP_DEFAULT_SEO} />
            <Head>
              <meta
                name='viewport'
                content='width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no,viewport-fit=cover'
              />
              {/* zigzag.kr 사이트 소유권 확인 */}
              <meta name='naver-site-verification' content='85adebb942c2b6871fb514e0de55960061731938' />
              {/* www.zigzag.kr 사이트 소유권 확인 */}
              <meta name='naver-site-verification' content='e3eb8252cc5871414c3a546ee4181a2478795dbf' />
            </Head>
            <Preconnect />
            <ZigzagWebMarketingScript
              contentSecurityNonce={contentSecurityNonce}
              skip_call_mtm={pageProps.skip_call_mtm}
            />
            <SocialProfileJsonLd
              type='Person'
              name='지그재그 스토어'
              url='https://www.zigzag.kr'
              sameAs={[
                'https://www.youtube.com/@ZIGZAG',
                'https://www.instagram.com/zigzag_korea',
                'https://apps.apple.com/kr/app/zigzag/id976131101',
                'https://play.google.com/store/apps/details?id=com.croquis.zigzag',
              ]}
            />
            <Global styles={reset} />
            <Global styles={onlyWebStyle} />
            <Global styles={font} />
            <Global styles={brazeStyle} />
            <AppContent Component={Component} pageProps={pageProps} />
          </Sentry.ErrorBoundary>
        </TrackerProvider>
      </UserAgentProvider>
    </AppConfigProvider>
  );
};

MyApp.getInitialProps = async ({ Component, ctx, router }: AppContext) => {
  // Component.getInitialProps 전에 setHeader 호출해야함
  setFrameOptions(ctx);

  const cookieString = ctx.req?.headers.cookie ?? '';
  const ZIGZAGUUID = getZigzagUuidFromRequestHeadersCookie(cookieString);

  let initial_page_props = {};
  if (Component.getInitialProps) {
    initial_page_props = await Component.getInitialProps(ctx);
  }

  const pageProps = {
    ...initial_page_props,
    user_agent: ctx.req?.headers['user-agent'] ?? '',
    user_locale,
    currency_meta,
    is_pdp: isPdp(router),
    ZIGZAGUUID,
    _nextI18Next: {
      initialLocale: user_locale.language,
    },
    ROUTES_TITLE_DATA: await getPublicLocale(user_locale.language),
  };

  return { pageProps };
};

async function getPublicLocale(language: unknown): Promise<Record<string, string>> {
  try {
    if (typeof window === 'undefined') {
      return process.env.LOCALE_HASH
        ? require(`../public/locales/${language}/route-title.${process.env.LOCALE_HASH}.json`)
        : require(`../public/locales/${language}/route-title.json`);
    } else {
      const ROUTES_TITLE_DATA = await fetch(`/locales/${language}/route-title.${process.env.LOCALE_HASH}.json`).then(
        (res) => res.json(),
      );
      return ROUTES_TITLE_DATA;
    }
  } catch (error) {
    console.error(error);
    return {};
  }
}

export default appWithTranslation(MyApp, i18NextOptionForWebsite);

function isPdp(router: Router) {
  try {
    return router.asPath.includes('/catalog/products/') || router.asPath.includes('/raffle/detail/');
  } catch (error) {
    return false;
  }
}

function setFrameOptions({ req, res }: AppContext['ctx']) {
  if (!req || !res) {
    return;
  }
  try {
    const { hostname } = new URL(req.headers.referer || '');
    if (hostname === 'localhost' || hostname.endsWith('zigzag.kr') || hostname.endsWith('kakaostyle.com')) {
      return;
    } else {
      res.setHeader('X-Frame-Options', 'DENY');
    }
  } catch (e) {
    res.setHeader('X-Frame-Options', 'DENY');
  }
}

const Wrapper = styled.div`
  background: #fff;
  max-width: 600px;
  margin: 0 auto;
  min-height: 100vh;
`;
