export function convertToSupertossUrl(url: string): string {
  try {
    const encodedUrl = encodeURIComponent(url);
    return `supertoss://m/shopping-zigzag/web?url=${encodedUrl}`;
  } catch (error) {
    console.error('Invalid URL:', error);

    return '';
  }
}
