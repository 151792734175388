import React, { ReactText, useCallback, useEffect, useMemo, useState } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { I18nextProvider, useTranslation } from 'react-i18next';

import { ThemeProvider, ThemeProviderProps } from '@common/styles/theme-provider';

import { BottomDialog, BottomDialogOption } from './dump/BottomDialog';

export function useBottomDialog(color_scheme?: ThemeProviderProps['colorScheme']) {
  const awaitingPromiseRef = React.useRef<{
    resolve: (id: ReactText) => void;
    reject: () => void;
  }>();
  const { i18n } = useTranslation();
  const [option, setOption] = useState<BottomDialogOption>();

  const handleSubmit = useCallback(
    (id: ReactText) => {
      awaitingPromiseRef.current?.resolve(id);
      setOption(undefined);
    },
    [setOption],
  );

  const buttons = useMemo(() => {
    return option?.buttons?.map((value, index) => ({
      ...value,
      onClick: () => {
        value.onClick?.();
        handleSubmit(value.id ?? index);
      },
    }));
  }, [handleSubmit, option?.buttons]);

  const wrapper = useMemo(
    () => (typeof document !== 'undefined' ? document.body.appendChild(document.createElement('div')) : null),
    [],
  );

  const dispose = useCallback(() => {
    if (!wrapper) {
      return;
    }
    setTimeout(() => {
      unmountComponentAtNode(wrapper);
      setTimeout(() => {
        if (document.body.contains(wrapper)) {
          document.body.removeChild(wrapper);
        }
      });
    });
  }, [wrapper]);

  const handleOutsideClick = useCallback(() => {
    if (option?.disableBackdropClick) {
      return;
    }
    if (option?.canBackDropResolve) {
      awaitingPromiseRef.current?.resolve('skip');
    }
    setOption(undefined);
  }, [option?.canBackDropResolve, option?.disableBackdropClick]);

  const active = useMemo(() => {
    return Boolean(option);
  }, [option]);

  const dialog = useMemo(() => {
    if (color_scheme) {
      return (
        <ThemeProvider colorScheme={color_scheme}>
          <I18nextProvider i18n={i18n}>
            <BottomDialog {...{ ...option, active, handleOutsideClick, buttons }} />
          </I18nextProvider>
        </ThemeProvider>
      );
    }

    return (
      <I18nextProvider i18n={i18n}>
        <BottomDialog {...{ ...option, active, handleOutsideClick, buttons }} />
      </I18nextProvider>
    );
  }, [option, active, buttons, handleOutsideClick, i18n, color_scheme]);

  const open = (open_option: BottomDialogOption) => {
    setOption(open_option);
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const close = () => {
    setOption(undefined);
  };

  useEffect(() => {
    render(dialog, wrapper);
  }, [dialog, wrapper]);
  useEffect(() => {
    return () => {
      dispose();
    };
  }, [dispose]);
  return { open, close };
}
