import { getRuntimeConfigValue } from '@common/next-runtime-config';
import { SiteId } from '@common/site-manager';

import { useInitBraze } from '../../braze';
import { MobonOneTagScript, usePageLoadMobonScript } from '../../mobon';
import { GoogleAnalytics } from './GoogleAnalytics';

interface ZigzagWebMarketingScriptProps {
  contentSecurityNonce: string;
  skip_call_mtm?: boolean;
}

export const ZigzagWebMarketingScript: React.FC<ZigzagWebMarketingScriptProps> = ({
  contentSecurityNonce,
  skip_call_mtm = false,
}) => {
  const site_id = SiteId.ZIGZAG;
  const is_apps_store = false;

  const braze_api_key = getRuntimeConfigValue('braze_api_key', site_id, is_apps_store) as string;
  const one_tag_service_key = getRuntimeConfigValue('one_tag_service_key', site_id, is_apps_store) as string;

  useInitBraze({ brazeApiKey: braze_api_key, contentSecurityNonce });

  usePageLoadMobonScript({ skip_call_mtm });

  return (
    <>
      <MobonOneTagScript one_tag_service_key={one_tag_service_key} />
      <GoogleAnalytics />
    </>
  );
};
