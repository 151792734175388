import React, { forwardRef } from 'react';
import type { ProductCardProps } from '@croquiscom-pvt/zds';
import { css } from '@emotion/react';

import { ThumbnailLikeButton } from '../components/ThumbnailLikeButton';
import { MetadataLikeButton } from './components/MetadataLikeButton';
import { MetadataPlpBadgeItems, MetadataPlpBadgeItemsProps } from './components/MetadataPlpBadgeItems';
import { MetadataPrice, MetadataPriceProps } from './components/MetadataPrice';
import { MetadataReviewWithFomo, MetadataReviewWithFomoProps } from './components/MetadataReviewWithFomo';
import { MetadataRoot, MetadataRootProps } from './components/MetadataRoot';
import { MetadataTitle, MetadataTitleProps } from './components/MetadataTitle';
import {
  getItemPropsByHorizontalProductCardProps,
  ProductCardLink,
  ProductCardLinkBaseItemProps,
  ProductCardLinkProps,
} from './components/ProductCardLink';
import { ProductCardRoot } from './components/ProductCardRoot';
import { Thumbnail, ThumbnailProps } from './components/Thumbnail';
import { ThumbnailEmblem, ThumbnailEmblemProps } from './components/ThumbnailEmblem';
import { ThumbnailNudge, ThumbnailNudgeProps } from './components/ThumbnailNudge';
import { ThumbnailRank, ThumbnailRankProps } from './components/ThumbnailRank';
import { useSizeByThumbnailWidth } from './hooks/useSizeByThumbnailWidth';
import { useVisibleOfBlocks } from './hooks/useVisibleOfBlocks';

interface HorizontalProductCardThumbnailProps
  extends Pick<ThumbnailProps, 'imageUrl' | 'webpImageUrl' | 'overlayImageUrl'>,
    Pick<ThumbnailRankProps, 'rank'>,
    Pick<ThumbnailEmblemProps, 'emblemBadgeList'>,
    Pick<ThumbnailNudgeProps, 'nudgeBadgeList'> {}

interface HorizontalProductCardMetadataProps
  extends Pick<MetadataTitleProps, 'title'>,
    Pick<MetadataPriceProps, 'price' | 'emblemItems' | 'salesStatus'>,
    Pick<MetadataPlpBadgeItemsProps, 'plpBadgeList'>,
    Pick<MetadataReviewWithFomoProps, 'review' | 'fomo'> {}

export interface HorizontalProductCardProps
  extends Pick<ProductCardProps, 'soldOut'>,
    Pick<ProductCardLinkProps, 'href' | 'renderProductCardLink'>,
    Pick<ProductCardLinkBaseItemProps, 'browsingType' | 'shopId' | 'shopProductNo' | 'productUrl'> {
  /** productId는 찜하기 상태 확인 및 ProductCardLink href를 위해 사용 */
  productId: string | null;
  thumbnail: HorizontalProductCardThumbnailProps;
  metadata: HorizontalProductCardMetadataProps;

  /**
   * 찜하기 버튼이 노출될 위치를 지정합니다.
   * 추가로 likeButtonType을 `thumbnail`로 지정하여도 `thumbnailWidth`가 79이하인 경우 메타데이터 영역에 표시됩니다.
   * @default thumbnail
   */
  likeButtonType?: 'thumbnail' | 'metadata';

  thumbnailWidth: ThumbnailProps['width'];
  thumbnailRatio?: ThumbnailProps['ratio'];

  metadataAlign?: MetadataRootProps['align'];

  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onLikeClick?: (isLiked: boolean, event: React.MouseEvent<HTMLSpanElement>) => void;
}

const thumbnailLikeButtonCss = css`
  z-index: 1;
`;

export const HorizontalProductCard = forwardRef<HTMLDivElement, HorizontalProductCardProps>((props, ref) => {
  const {
    soldOut,
    href,
    renderProductCardLink,
    productId,
    thumbnail,
    metadata,
    likeButtonType = 'thumbnail',
    thumbnailWidth,
    thumbnailRatio,
    metadataAlign,
    onClick,
    onLikeClick,
  } = props;

  const { size, metadataSize } = useSizeByThumbnailWidth(thumbnailWidth);

  const {
    isVisibleThumbnailLikeButton,
    isVisibleMetadataLikeButton,
    isVisibleThumbnailEmblem,
    isVisibleThumbnailNudge,
  } = useVisibleOfBlocks({
    thumbnailWidth,
    likeButtonType,
  });

  const item = getItemPropsByHorizontalProductCardProps(props);

  return (
    <ProductCardRoot className='product-card' ref={ref} size={size} soldOut={soldOut}>
      <ProductCardLink
        className='product-card-link'
        item={item}
        href={href}
        renderProductCardLink={renderProductCardLink}
        onClick={onClick}
      />
      <Thumbnail
        className='product-card-thumbnail'
        imageUrl={thumbnail.imageUrl}
        webpImageUrl={thumbnail.webpImageUrl}
        overlayImageUrl={thumbnail.overlayImageUrl}
        width={thumbnailWidth}
        ratio={thumbnailRatio}
      >
        <ThumbnailRank className='product-card-thumbnail-rank' rank={thumbnail.rank} />
        {isVisibleThumbnailNudge && (
          <ThumbnailNudge className='product-card-thumbnail-nudge' nudgeBadgeList={thumbnail.nudgeBadgeList} />
        )}
        {isVisibleThumbnailEmblem && (
          <ThumbnailEmblem
            className='product-card-thumbnail-emblem'
            size={size}
            emblemBadgeList={thumbnail.emblemBadgeList}
          />
        )}
        {isVisibleThumbnailLikeButton && onLikeClick && (
          <ThumbnailLikeButton
            className='product-card-thumbnail-like-button'
            css={thumbnailLikeButtonCss}
            productId={productId}
            onLikeClick={onLikeClick}
          />
        )}
      </Thumbnail>
      <MetadataRoot className='product-card-metadata' size={metadataSize} align={metadataAlign}>
        <MetadataTitle className='product-card-metadata-title' size={metadataSize} title={metadata.title} />
        <MetadataPrice
          className='product-card-metadata-price'
          size={metadataSize}
          salesStatus={metadata.salesStatus}
          price={metadata.price}
          emblemItems={metadata.emblemItems}
          thumbnailEmblemBadgeList={thumbnail.emblemBadgeList}
        />
        <MetadataPlpBadgeItems
          className='product-card-metadata-plp-badge-items'
          size={metadataSize}
          plpBadgeList={metadata.plpBadgeList}
        />
        <MetadataReviewWithFomo
          className='product-card-metadata-review-with-fomo'
          size={metadataSize}
          review={metadata.review}
          fomo={metadata.fomo}
        />
      </MetadataRoot>
      {isVisibleMetadataLikeButton && onLikeClick && (
        <MetadataLikeButton
          className='product-card-metadata-like-button'
          size={metadataSize}
          productId={productId}
          onLikeClick={onLikeClick}
        />
      )}
    </ProductCardRoot>
  );
});
