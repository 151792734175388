import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

export function isMobileDevice(ua: string): boolean {
  return (
    ua.match(
      /iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i,
    ) != null || ua.match(/LG|SAMSUNG|Samsung/) != null
  );
}

export function isTossApp(ua: string): boolean {
  return /TossApp/i.test(ua);
}

export function isZigzagApp(ua: string): boolean {
  return /(zigzag)/i.test(ua);
}

function getClientUA(): string {
  return typeof navigator !== 'undefined' ? navigator.userAgent : '';
}

interface UserAgentContextValue {
  userAgent: string;
  isToss: boolean;
  isZigzag: boolean;
  isMobile: boolean;
}

const UserAgentContext = createContext<UserAgentContextValue>({
  userAgent: '',
  isToss: false,
  isZigzag: false,
  isMobile: false,
});

/** SSR + CSR에서 UA 동기화 */
export function UserAgentProvider({
  initialUserAgent = '',
  children,
}: {
  initialUserAgent?: string;
  children: React.ReactNode;
}) {
  const [userAgent, setUserAgent] = useState(initialUserAgent);
  const isToss = useMemo(() => {
    return isTossApp(userAgent);
  }, [userAgent]);
  const isZigzag = useMemo(() => {
    return isZigzagApp(userAgent);
  }, [userAgent]);

  const isMobile = useMemo(() => {
    return isMobileDevice(userAgent);
  }, [userAgent]);

  useEffect(() => {
    const clientUA = getClientUA();
    if (clientUA && clientUA !== userAgent) {
      setUserAgent(clientUA);
    }
  }, []);

  return (
    <UserAgentContext.Provider value={{ userAgent, isToss, isZigzag, isMobile }}>{children}</UserAgentContext.Provider>
  );
}

/** userAgent 직접 접근 */
export function useUserAgent() {
  return useContext(UserAgentContext).userAgent;
}

/** Toss 여부 판별 */
export function useIsToss() {
  return useContext(UserAgentContext).isToss;
}

/** 지그재그 앱 여부 판별 */
export function useIsZigzag() {
  return useContext(UserAgentContext).isZigzag;
}

/** 모바일 여부 판별 */
export function useIsMobile() {
  return useContext(UserAgentContext).isMobile;
}
