import type { ProductCardProps } from '@croquiscom-pvt/zds';

import { BadgeItemProps } from '../components/BadgeItem';
import { HorizontalProductCardProps } from './HorizontalProductCard';
import { BadgeListItemProps, UxGoodsCardItemForHorizontalProductCard } from './types';

export function covertBadgeItemPropsBySize(item: BadgeListItemProps, size?: ProductCardProps['size']): BadgeItemProps {
  // 슬랙 논의 스레드: https://croquis.slack.com/archives/C05CEF8L0CA/p1708479218526989?thread_ts=1708415530.103649&cid=C05CEF8L0CA
  // ZDS 3.5 버전에서는 서버에서 가져오는 badge_list에서 small_dark_image_url 및 small_image_url 값이 null인 지면이 많아서 우선 small 뱃지가 없는 경우 기본 뱃지로 대체하는 코드를 적용.
  // ZDS 4.0에서는 검토 후 변경이 필요할 수 있음.
  return {
    darkImageUrl: (size === 'small' ? (item.smallDarkImageUrl ?? item.darkImageUrl) : item.darkImageUrl) ?? null,
    imageUrl: (size === 'small' ? (item.smallImageUrl ?? item.imageUrl) : item.imageUrl) ?? null,
  };
}

export function getHorizontalProductCardPropsByUxGoodsCardItemForHorizontalProductCard(
  item: UxGoodsCardItemForHorizontalProductCard,
): Pick<
  HorizontalProductCardProps,
  'soldOut' | 'browsingType' | 'productId' | 'shopId' | 'shopProductNo' | 'productUrl' | 'thumbnail' | 'metadata'
> {
  return {
    soldOut: item.sales_status === 'SOLD_OUT',
    browsingType: item.browsing_type,
    productId: item.catalog_product_id,
    shopId: item.shop_id,
    shopProductNo: item.shop_product_no,
    productUrl: item.product_url,
    thumbnail: {
      imageUrl: item.image_url ?? '',
      webpImageUrl: item.webp_image_url ?? '',
      overlayImageUrl: item.thumbnail_overlay_image_url ?? '',
      nudgeBadgeList: item.thumbnail_nudge_badge_list?.map((badge) => {
        return {
          imageUrl: badge.image_url,
          darkImageUrl: badge.dark_image_url,
          smallImageUrl: badge.small_image_url,
          smallDarkImageUrl: badge.small_dark_image_url,
        };
      }),
      emblemBadgeList:
        item.thumbnail_emblem_badge_list?.map((badge) => {
          return {
            type: badge.type,
            imageUrl: badge.image_url,
            darkImageUrl: badge.dark_image_url,
            smallImageUrl: badge.small_image_url,
            smallDarkImageUrl: badge.small_dark_image_url,
          };
        }) ?? [],
    },
    metadata: {
      title: {
        brandName: item.shop_name ?? null,
        productName: item.title ?? '',
        brandNameBadgeList: item.brand_name_badge_list
          ? item.brand_name_badge_list.map((badge) => {
              return {
                imageUrl: badge.image_url,
                darkImageUrl: badge.dark_image_url,
                smallImageUrl: badge.small_image_url,
                smallDarkImageUrl: badge.small_dark_image_url,
              };
            })
          : undefined,
      },
      price: {
        maxPrice: item.max_price,
        finalPrice: item.final_price,
        discountPercent: item.discount_rate,
        discountInfo: item.discount_info
          ? {
              color: item.discount_info.color,
              imageUrl: item.discount_info.image_url,
              title: item.discount_info.title,
            }
          : null,
      },
      emblemItems: item.metadata_emblem_badge_list
        ? item.metadata_emblem_badge_list.map((badge) => {
            return {
              imageUrl: badge.image_url,
              darkImageUrl: badge.dark_image_url,
              smallImageUrl: badge.small_image_url,
              smallDarkImageUrl: badge.small_dark_image_url,
            };
          })
        : undefined,
      plpBadgeList:
        item.badge_list?.map((badge) => {
          return {
            imageUrl: badge.image_url,
            darkImageUrl: badge.dark_image_url,
            smallImageUrl: badge.small_image_url,
            smallDarkImageUrl: badge.small_dark_image_url,
          };
        }) ?? [],
      review:
        item.review_score && item.display_review_count
          ? {
              score: item.review_score,
              reviewCount: item.display_review_count,
            }
          : null,
      fomo: item.fomo
        ? {
            iconImageUrl: item.fomo.icon_image_url,
            text: item.fomo.text,
          }
        : null,
    },
  };
}
