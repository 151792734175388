/* eslint @nx/enforce-module-boundaries: 'warn' */
import React, { FC, ReactNode } from 'react';
import { vars } from '@croquiscom-pvt/zds/tokens';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { AnimatePresence, createDomMotionComponent, DraggableProps } from 'framer-motion';

import { usePreventScroll } from '@common/hooks';
import {
  Gray_Light_600,
  Gray_Light_900,
  Gray_Light_White,
  safeAreaInsetBottom,
  Typography,
  web_max_width,
} from '@common/styles';

import { RoundFilledButton, RoundFilledButtonProps } from './RoundFilledButton';

export interface BottomDialogButtonOption<OvverideProps = object, Ovveride = FC<OvverideProps>>
  extends RoundFilledButtonProps {
  id?: string;
  onClick?: () => void;
  override?: Ovveride;
  overrideProps?: OvverideProps;
}

export interface BottomDialogOption {
  image?: React.ReactNode;
  title?: React.ReactNode;
  text?: React.ReactNode;
  content?: React.ReactNode;
  buttons?: BottomDialogButtonOption[];
  disableBackdropClick?: boolean;
  canBackDropResolve?: boolean;
  children?: ReactNode;
  radius?: boolean;
  containerHeight?: string;
  /**
   * @description window scroll을 할 수 있게 하는지 여부
   * @default false
   */
  canScroll?: boolean;
  buttonDirection?: 'column' | 'row';
  paddingTop?: string;
  paddingBottom?: string;
  onClose?: () => void;
  dragCloseEnabled?: boolean;
  close?: () => void;
  onOutsideClick?: () => void;
}

export interface BottomDialogProps extends BottomDialogOption {
  active?: boolean;
  handleOutsideClick?: () => void;
}

/** useBottomDialog 와 Dialog에 사용하기 위한 컴포넌트  */
export function BottomDialog({
  image,
  title,
  text,
  content,
  buttons,
  disableBackdropClick,
  children,
  radius = true,
  buttonDirection,
  active,
  containerHeight,
  canScroll,
  paddingTop = '48px',
  paddingBottom = '24px',
  handleOutsideClick = () => undefined,
  dragCloseEnabled,
  onClose,
  close,
  onOutsideClick,
}: BottomDialogProps) {
  usePreventScroll(Boolean(canScroll) === false && Boolean(active) === true);

  const draggableProps: DraggableProps = dragCloseEnabled
    ? {
        drag: 'y',
        dragDirectionLock: true,
        dragElastic: { top: 0, bottom: 0.3 },
        dragConstraints: { top: 0, bottom: 0 },
        onDragEnd: (event, info) => {
          if (info.offset.y > 50) {
            const closeHandler = disableBackdropClick ? (onClose ?? close) : handleOutsideClick;
            closeHandler?.();
          }
        },
      }
    : {};

  return (
    <AnimatePresence>
      {active && (
        <div
          css={css`
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            z-index: 9998;
          `}
        >
          <Overlay
            animate={{ background: 'rgba(0, 0, 0, 0.7)' }}
            {...(!disableBackdropClick && {
              onClick: onOutsideClick ?? handleOutsideClick,
            })}
          />
          <Container
            {...draggableProps}
            initial={{ translateY: '100%' }}
            animate={{ translateY: 0 }}
            exit={{ translateY: '100%' }}
            transition={{ type: 'tween' }}
            css={css`
              border-radius: ${!radius ? '0' : '8px 8px 0 0'};
              height: ${containerHeight};
              padding-top: ${paddingTop};
              ${safeAreaInsetBottom(paddingBottom)}
            `}
          >
            {dragCloseEnabled && (
              <DragHeader>
                <DragBar />
              </DragHeader>
            )}
            {children ? (
              children
            ) : (
              <>
                {image && image}
                {title && <Title className={Typography.HEAD_20_BOLD}>{title}</Title>}
                {text && <Text className={Typography.BODY_15_MEDIUM}>{text}</Text>}
                {content && content}
                {buttons && (
                  <ButtonWrapper flexDirection={buttonDirection}>
                    {buttons.map((button_props) => {
                      if (button_props.override) {
                        const OverrideButton = button_props.override;
                        return (
                          <OverrideButton key={button_props.id} {...button_props} {...button_props.overrideProps} />
                        );
                      } else {
                        return <StyledRoundFilledButton key={button_props.id} {...button_props} />;
                      }
                    })}
                  </ButtonWrapper>
                )}
              </>
            )}
          </Container>
        </div>
      )}
    </AnimatePresence>
  );
}

const Overlay = styled(createDomMotionComponent('div'))`
  width: 100%;
  height: 100%;
`;

const Title = styled.div`
  color: ${Gray_Light_900};
  padding: 0 24px;
  margin: 0 0 8px;
  white-space: pre-wrap;
`;

const Text = styled.div`
  color: ${Gray_Light_600};
  padding: 0 24px;
  margin: 0 0 32px;
  white-space: pre-wrap;
`;

const Container = styled(createDomMotionComponent('div'))`
  position: absolute;
  z-index: 9999;
  margin: 0 auto;
  width: 100%;
  max-width: ${web_max_width}px;
  background: ${Gray_Light_White};
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
`;

const DragHeader = styled.div`
  padding: 12px 0 8px;
  position: absolute;
  z-index: 10000;
  margin: 0 auto;
  width: 100%;
  top: 0;
  left: 0;
`;

const DragBar = styled(createDomMotionComponent('div'))`
  margin: 0 auto;
  width: 40px;
  height: 4px;
  border-radius: 2px;
  background: ${vars.v3Color.gray200};
  cursor: 'grab';
`;

const ButtonWrapper = styled.div<{ flexDirection?: string }>`
  display: flex;
  justify-content: space-around;
  padding: 0 16px;
  gap: 8px;
  flex-direction: ${({ flexDirection }) => flexDirection};
`;

const StyledRoundFilledButton = styled(RoundFilledButton)`
  margin-top: 8px;
  width: 100%;
  display: block;
  cursor: pointer;
`;
