import {
  abg,
  addCart,
  addOrder,
  back,
  callZigzagFunction,
  cbk,
  ckla,
  close,
  compareWithAppVersion,
  createOrderSheet,
  csp,
  deleteCart,
  deleteOrder,
  gsbh,
  gssl,
  isAndroid,
  isBrowser,
  isIOS,
  logAppsFlyer,
  logBraze,
  logFacebook,
  logFirebase,
  login,
  loginWithRedirectUrl,
  logout,
  open,
  productReviewPhotoDetail,
  relatedItemList,
  SavedProduct,
  SavedProductStatus,
  SaveProduct,
  sendPostMessage,
  setTitle,
  st,
  sual,
  tt,
  updateCart,
  updateOrder,
  UserAccount,
  zigzagHandler,
} from '@croquiscom-pvt/web2app';

import { isZigzag } from '@common/device-manager';

import { UserShippingAddressBookFragment } from '../graphql/fragment_types.2';

export interface UBL {
  category: string;
  navigation: string;
  navigation_sub?: string;
  object_type?: string;
  object_id?: string | null;
  object_section?: string;
  object_url?: string;
  object_idx?: number;
  data?: string;
}

// UMD 업데이트(rbk, abk, sp, rsp)에 필요한 데이터
export type UserMetaData = { [key: string]: any };

// UMD 업데이트시 적재 되는 UBL 의 "data"
export type UserMetaDataUBLData = { [key: string]: string | number | boolean | null | undefined | UserMetaDataUBLData };
export interface ActionOptionalLog extends Pick<UBL, 'object_section' | 'object_idx'>, UserMetaDataUBLData {
  // passThrough 가 falsy 일 경우 넘어 오는 값을 logs.server_log 에 할당, true 일 경우 logs 로 바로 할당
  passThrough?: boolean;
}

// Note: UBL data 항목에 적재되는 값을 확장 하기 위해 해당 부분 수정 하였습니다
const parseUserMetaData = (metadata: UserMetaData, log?: ActionOptionalLog) => {
  if (!log) {
    return {
      ...metadata,
      logs: isAndroid() ? JSON.stringify({}) : undefined,
    };
  }

  // 기존 parsing 로직과 호환유지를 위해 passThrough 값이 true 일 경우에만 rest 값을 로그로 바로 할당합니다.
  const { passThrough, ...rest } = log;
  const logs = passThrough ? rest : { server_log: rest };

  if (compareWithAppVersion('7.85.0') && typeof logs.data === 'string') {
    logs.data = JSON.parse(logs.data);
  }

  return {
    ...metadata,
    logs: isAndroid() ? JSON.stringify(logs) : logs,
  };
};

function sendUbl(log: UBL) {
  /**
   * AOS 7.85.0인 경우 data.data 형태로 data 전송
   * https://croquis.slack.com/archives/C031FJXQNTH/p1706079538880849?thread_ts=1706058581.110729&cid=C031FJXQNTH
   */
  const shouldWrapNestedDataField = isAndroid() && compareWithAppVersion('7.85.0') && !compareWithAppVersion('7.85.1');
  if (log.data && shouldWrapNestedDataField) {
    log.data = { data: log.data } as any;
  }

  if (isAndroid()) {
    callZigzagFunction('subl')({ log: JSON.stringify(log) });
    return;
  }
  callZigzagFunction('subl')({ log });
}

const addBookMark = (shopMainDomain: string, logs?: ActionOptionalLog) => {
  return new Promise<boolean>((resolve) => {
    callZigzagFunction('abk')(parseUserMetaData({ shopMainDomain }, logs));
    zigzagHandler('onBookmarkStatus', (maps) => resolve(maps[shopMainDomain]));
  });
};

const removeBookMark = (shopMainDomain: string, logs?: ActionOptionalLog) => {
  return new Promise<boolean>((resolve) => {
    callZigzagFunction('rbk')(parseUserMetaData({ shopMainDomain }, logs));
    zigzagHandler('onBookmarkStatus', (maps) => resolve(maps[shopMainDomain]));
  });
};

const saveProduct = (saveProductData: SaveProduct, logs?: ActionOptionalLog & { main_title?: string }) => {
  return new Promise<SavedProductStatus>((resolve) => {
    callZigzagFunction('sp')(parseUserMetaData(saveProductData, logs));
    zigzagHandler('onSavedProductStatus', (savedProductStatusList) => resolve(savedProductStatusList[0]));
  });
};

const removeSavedProduct = (savedProductData: SavedProduct, logs?: ActionOptionalLog) => {
  return new Promise<SavedProductStatus>((resolve) => {
    callZigzagFunction('rsp')(parseUserMetaData(savedProductData, logs));
    zigzagHandler('onSavedProductStatus', (savedProductStatusList) => resolve(savedProductStatusList[0]));
  });
};

const checkUserAccount = () => {
  return new Promise<UserAccount>((resolve) => {
    callZigzagFunction('getUserAccount')();
    zigzagHandler('onUserAccount', (userAccount) => {
      resolve(userAccount);
    });
  });
};

// ZigzagEvent
export const getShopProductNo = (shop_main_domain: string, product_no: string) => shop_main_domain + '$' + product_no;

export const ZigzagEvent = {
  sual: (log: string) => sual('action', log),
  subl: (log: UBL) => sendUbl(log),
  sual_app: (log: string) => sual('app', log),
  sual_info: (log: string) => sual('info', log),
  /**
   * @deprecated 삭제 예정. common/app-manager의 ZigzagEvent 사용.
   */
  st,
  /**
   * @deprecated 삭제 예정. common/app-manager의 ZigzagEvent 사용.
   */
  tt,
  /**
   * @deprecated 삭제 예정. common/app-manager의 ZigzagEvent 사용.
   */
  ckla,
  /**
   * @deprecated 삭제 예정. common/app-manager의 ZigzagEvent 사용.
   */
  cbk,
  /**
   * @deprecated 삭제 예정. common/app-manager의 ZigzagEvent 사용.
   */
  abk: (shopMainDomain: string, logs?: ActionOptionalLog) => addBookMark(shopMainDomain, logs),
  /**
   * @deprecated 삭제 예정. common/app-manager의 ZigzagEvent 사용.
   */
  rbk: (shopMainDomain: string, logs?: ActionOptionalLog) => removeBookMark(shopMainDomain, logs),
  /**
   * @deprecated 삭제 예정. common/app-manager의 ZigzagEvent 사용.
   */
  gssl,
  /**
   * @deprecated 삭제 예정. common/app-manager의 ZigzagEvent 사용.
   */
  csp,
  /**
   * @deprecated 삭제 예정. common/app-manager의 ZigzagEvent 사용.
   */
  sp: (saveProductData: SaveProduct, logs?: ActionOptionalLog & { main_title?: string }) =>
    saveProduct(saveProductData, logs),
  /**
   * @deprecated 삭제 예정. common/app-manager의 ZigzagEvent 사용.
   */
  rsp: (savedProductData: SavedProduct, logs?: ActionOptionalLog) => removeSavedProduct(savedProductData, logs),
  /**
   * @deprecated 삭제 예정. common/app-manager의 ZigzagEvent 사용.
   */
  abg,
  /**
   * @deprecated 삭제 예정. common/app-manager의 ZigzagEvent 사용.
   */
  cua: checkUserAccount,
  /**
   * @deprecated 삭제 예정. common/app-manager의 ZigzagEvent 사용.
   */
  gsbh,
  logBraze: (eventName: string, parameters?: Record<string, unknown>) => {
    if (compareWithAppVersion('6.77.0')) {
      logBraze(eventName, parameters);
    }
  },
  logAppsFlyer: (eventName: string, parameters?: Record<string, unknown>) => {
    if (compareWithAppVersion('6.77.0')) {
      logAppsFlyer(eventName, parameters);
    }
  },
  logFacebook: (eventName: string, parameters?: Record<string, unknown>) => {
    if (compareWithAppVersion('6.77.0')) {
      logFacebook(eventName, parameters);
    }
  },
  logFirebase: (eventName: string, parameters?: Record<string, unknown>) => {
    if (compareWithAppVersion('6.77.0')) {
      logFirebase(eventName, parameters);
    }
  },
  /**
   * @deprecated 삭제 예정. common/app-manager의 ZigzagEvent 사용.
   */
  sel: (url: string) => {
    callZigzagFunction('sel')({ url });
  },
  /**
   * @deprecated 삭제 예정. common/app-manager의 ZigzagEvent 사용.
   */
  showPopup: (url: string) => {
    callZigzagFunction('showPopup')({ url });
  },
};

// misc
export const getMobileOS = (userAgent: NavigatorID['userAgent']) => {
  const userAgentLowerCase = userAgent.toLowerCase();

  if (userAgentLowerCase.match(/iPhone|iPad|iPod|\bOS X\b/gi)) {
    return 'ios';
  } else if (userAgentLowerCase.match(/windows phone|iemobile|wpdesktop/i)) {
    return 'windows';
  } else if (userAgentLowerCase.match(/blackberry|bb10|playbook/i)) {
    return 'blackberry';
  } else if (userAgentLowerCase.match(/android/i)) {
    return 'android';
  } else {
    return 'unknown';
  }
};

export const checkZPayFunctionExists = (browser_window: any) => {
  return Boolean(
    browser_window.zpay ||
      (browser_window.webkit && browser_window.webkit.messageHandlers && browser_window.webkit.messageHandlers.zpay),
  );
};

// Zpay
export enum ZpayPostMessageType {
  REFRESH_SIMPLE_BANK_ACCOUNT_LIST = 'refresh_simple_bank_account_list',
  PURCHASE_BY_SIMPLE_PAY = 'purchase_by_simple_pay',
  ADD_PRODUCT_REVIEW = 'add_product_review',
  PURCHASE_ORDER_SHEET_BY_SIMPLE_PAY = 'purchase_order_sheet_by_simple_pay',
  SET_COUPON_PAYLOADS = 'set_coupon_payloads',
  FORCE_REFRESH_PAGE = 'force_refersh_page',
  INVALIDATION_ADDRESS = 'invalidation_address',
  SELECT_ADDRESS = 'select_address',
  DELETE_ADDRESS = 'delete_address',
  DIRECT_CHECKOUT = 'direct_checkout',
  SELECT_POSTNO_ADDRESS = 'select_postno_address',
  SET_REFUND_BANK_ACCOUNT = 'set_refund_bank_account',
  CANCEL_AND_ADD_TO_CART = 'cancel_and_add_to_cart',
  RELOAD_CART_PAGE = 'reload_cart_page',
}

const sendPostMessageEvent = (type: ZpayPostMessageType, data: any = {}, origin?: string) => {
  const targetOrigin = isBrowser() ? origin || window.location.origin : '*';

  if (isZigzag()) {
    sendPostMessage({ type, ...data }, targetOrigin);
  } else {
    if (window.opener !== null) {
      const strMessage = JSON.stringify({ type, ...data });
      window.opener.postMessage(strMessage, targetOrigin);
    }
  }
};

export const ZpayEvent = {
  login,
  loginWithRedirectUrl,
  logout,
  back,
  close,
  createOrderSheet,
  open,
  setTitle,
  addCart,
  deleteCart,
  reloadCart: () => {
    sendPostMessageEvent(ZpayPostMessageType.RELOAD_CART_PAGE);
  },
  updateCart: () => {
    if ((compareWithAppVersion('7.36.0') && isAndroid()) || (compareWithAppVersion('7.35.1') && isIOS())) {
      updateCart();
    }
  },
  addOrder,
  deleteOrder,
  updateOrder,
  productReviewPhotoDetail,
  relatedItemList,
  refreshSimpleBankAccountList: () => sendPostMessageEvent(ZpayPostMessageType.REFRESH_SIMPLE_BANK_ACCOUNT_LIST),
  purchaseBySimplePay: (password: string) =>
    sendPostMessageEvent(ZpayPostMessageType.PURCHASE_BY_SIMPLE_PAY, { password }),
  purchaseOrderSheetBySimplePay: (order_sheet_uuid: string, password: string) =>
    sendPostMessageEvent(ZpayPostMessageType.PURCHASE_ORDER_SHEET_BY_SIMPLE_PAY, { order_sheet_uuid, password }),
  setCouponPayloads: <T extends object>(coupon_payloads: T[]) =>
    sendPostMessageEvent(ZpayPostMessageType.SET_COUPON_PAYLOADS, { coupon_payloads }),
  invalidatonAddress: () => sendPostMessageEvent(ZpayPostMessageType.INVALIDATION_ADDRESS),
  selectAddress: (select_address: UserShippingAddressBookFragment) =>
    sendPostMessageEvent(ZpayPostMessageType.SELECT_ADDRESS, {
      select_address: {
        ...select_address,
        address: escape(select_address.address),
        detail_address: escape(select_address.detail_address || ''),
      },
    }),
  selectPostnoAddress: (post_code: string, address: string, detail_address: string, origin?: string) =>
    sendPostMessageEvent(
      ZpayPostMessageType.SELECT_POSTNO_ADDRESS,
      { post_code, address: escape(address), detail_address: escape(detail_address) },
      origin,
    ),
  deleteAddress: (address_id: string) => sendPostMessageEvent(ZpayPostMessageType.DELETE_ADDRESS, { address_id }),
  directCheckout: () => sendPostMessageEvent(ZpayPostMessageType.DIRECT_CHECKOUT),
  forceRefreshPage: () => sendPostMessageEvent(ZpayPostMessageType.FORCE_REFRESH_PAGE),
  setRefundBankAccount: (account_holder: string, account_number: string, bank_code: string) =>
    sendPostMessageEvent(ZpayPostMessageType.SET_REFUND_BANK_ACCOUNT, { account_holder, account_number, bank_code }),
  cancelAndAddtoCart: () => sendPostMessageEvent(ZpayPostMessageType.CANCEL_AND_ADD_TO_CART),
};
