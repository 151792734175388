export function formatPhoneNumber(value: string) {
  const number_value = value.replace(/\D+/g, '');

  if (number_value.length > 3 && number_value.length <= 7) {
    return number_value.replace(/(\d{3})(\d{1,4})/, '$1-$2');
  } else if (number_value.length > 7) {
    return number_value.replace(/(\d{3})(\d{4})(\d{1,4})/, '$1-$2-$3');
  }
  return number_value;
}
