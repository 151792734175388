import React, { HTMLAttributes, useState } from 'react';
import { compareWithAppVersion } from '@croquiscom-pvt/web2app';

import { useIsZigzag } from '@common/device-manager';
import { useBrowserEffect } from '@common/hooks';

import { LoadingDotBlack } from './LoadingDotBlack';
import { LoadingDotWhite } from './LoadingDotWhite';
import { WaveDots } from './WaveDots';

interface LoadingDot {
  color?: 'black' | 'white';
}

export const LoadingDot: React.FC<HTMLAttributes<HTMLSpanElement | HTMLDivElement>> = (props) => {
  const { color = 'black', ...restProps } = props;
  const isApp = useIsZigzag();
  const [isNewVersion, setIsNewVersion] = useState(false);

  useBrowserEffect(() => {
    if (compareWithAppVersion('7.90.0')) {
      setIsNewVersion(true);
    }
  }, []);

  if (isApp && !isNewVersion) {
    return <WaveDots {...restProps} />;
  }

  return color === 'black' ? <LoadingDotBlack {...restProps} /> : <LoadingDotWhite {...restProps} />;
};
