import { BaseConfigType } from './default';
import ProductionConfig from './production';

const Config: BaseConfigType = {
  ...ProductionConfig,
  assetPrefix: `https://beta.zigzag.kr/resources/${process.env.ARCH}/${process.env.IMAGE_TAG}_${process.env.WORKSPACE}`,
  sentry_dsn: 'https://00ef6e4ca50e4f2aad0eebbf2d69d10c@o314573.ingest.sentry.io/6036870',
  sentry_org: 'zigzag-alpha',
  baseDomain: 'beta.zigzag.kr',
  apiBaseUrl: 'https://api.beta.zigzag.kr/api/store',
  apiConsumerBaseUrl: 'https://api.beta.zigzag.kr/api/2',
  simplePayPassword:
    'https://api.beta.zigzag.kr/api/security-keypad/static/js/simple_pay/simple_pay_password_beta.js?t=20231218',
  appUrl: 'https://beta.zigzag.kr',
  redirectAppUrl: 'https://store.beta.zigzag.kr',
  contentUrl: 'https://content.beta.zigzag.kr',
  resUrl: 'https://cf.res.s.zigzag.kr',
  business_juso_api_key: 'U01TX0FVVEgyMDIyMTEwNzEzMDEzMjExMzE5Mjc=',
  zigzag_postcode_url: 'https://store.beta.zigzag.kr/postcode',
  zendesk_url: 'https://kakaostyle.zendesk.com/hc/p/requests-with-sso',
  /** @description marketing start*/
  ga4_id: '',
  amplitude_id: '',
  apps_flyer_pba_id: '',
  airbridge_app: 'zigzagbeta',
  airbridge_web_token: 'a7bdc36fb65d4de18b313ddb49c2c3a6',
  naver_account_id: '',
  appier_id: '',
  appier_track_id: '',
  braze_api_key: '3347f2c9-3ccf-4994-a196-3eaaa8b46288',
  one_tag_service_key: 'trtmtuzuuurdtrtmtuzuuu',
  /** marketing end */
  toss_client_id: 'bf1ocr42i3ymvaicoervdyv9g5n8v1lg',
};

export default Config;
