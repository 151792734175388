export enum UserNotificationCategory {
  BEAUTY = 'BEAUTY',
  DAILY_MISSION = 'DAILY_MISSION',
  EPICK = 'EPICK',
  EXPIRED_USER_BENEFIT = 'EXPIRED_USER_BENEFIT',
  INFORMATION = 'INFORMATION',
  MEMBERSHIP = 'MEMBERSHIP',
  NORMAL = 'NORMAL',
  ORDER = 'ORDER',
  PROMOTION_202406 = 'PROMOTION_202406',
  RESTOCK = 'RESTOCK',
  REVIEW = 'REVIEW',
  SALE_TAB = 'SALE_TAB',
  SAVED_PRODUCTS = 'SAVED_PRODUCTS',
  SHOP = 'SHOP',
  TALK_LOUNGE = 'TALK_LOUNGE',
  WEATHER = 'WEATHER',
  ZPAY = 'ZPAY',
  TEAM_PURCHASE = 'TEAM_PURCHASE',
  EPICK_NEW = 'EPICK_NEW',
  WEATHER_NEW = 'WEATHER_NEW',
  WISHLIST_PRICE_DROP = 'WISHLIST_PRICE_DROP',
  BEAUTY_ATTACK = 'BEAUTY_ATTACK',
  LOUNGE_TALK = 'LOUNGE_TALK',
}
