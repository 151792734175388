import React, { forwardRef } from 'react';
import type { ProductCardProps } from '@croquiscom-pvt/zds';
import { css } from '@emotion/react';

import { ThumbnailLikeButton } from '../components/ThumbnailLikeButton';
import { MetadataOneDayDelivery, MetadataOneDayDeliveryProps } from './components/MetadataOneDayDelivery';
import { MetadataPlpBadgeItems, MetadataPlpBadgeItemsProps } from './components/MetadataPlpBadgeItems';
import { MetadataPrice, MetadataPriceProps } from './components/MetadataPrice';
import { MetadataReviewWithFomo, MetadataReviewWithFomoProps } from './components/MetadataReviewWithFomo';
import { MetadataRoot } from './components/MetadataRoot';
import { MetadataTitle, MetadataTitleProps } from './components/MetadataTitle';
import {
  getItemPropsByVerticalProductCardProps,
  ProductCardLink,
  ProductCardLinkBaseItemProps,
  ProductCardLinkProps,
} from './components/ProductCardLink';
import { ProductCardRoot } from './components/ProductCardRoot';
import { Thumbnail, ThumbnailProps } from './components/Thumbnail';
import { ThumbnailEmblem, ThumbnailEmblemProps } from './components/ThumbnailEmblem';
import { ThumbnailNudge, ThumbnailNudgeProps } from './components/ThumbnailNudge';
import { ThumbnailRank, ThumbnailRankProps } from './components/ThumbnailRank';

interface VerticalProductCardThumbnailProps
  extends Pick<ThumbnailProps, 'imageUrl' | 'webpImageUrl' | 'jpegImageUrl' | 'overlayImageUrl'>,
    Pick<ThumbnailRankProps, 'rank'>,
    Pick<ThumbnailEmblemProps, 'emblemBadgeList'>,
    Pick<ThumbnailNudgeProps, 'nudgeBadgeList'> {}

interface VerticalProductCardMetadataProps
  extends Pick<MetadataTitleProps, 'title'>,
    Pick<MetadataPriceProps, 'price' | 'salesStatus'>,
    Pick<MetadataOneDayDeliveryProps, 'oneDayDelivery'>,
    Pick<MetadataPlpBadgeItemsProps, 'plpBadgeList'>,
    Pick<MetadataReviewWithFomoProps, 'review' | 'fomo'> {}

export interface VerticalProductCardProps
  extends Pick<ProductCardProps, 'size' | 'soldOut'>,
    Pick<ProductCardLinkProps, 'href' | 'renderProductCardLink'>,
    Pick<ProductCardLinkBaseItemProps, 'browsingType' | 'shopId' | 'shopProductNo' | 'productUrl'> {
  /** productId는 찜하기 상태 확인 및 ProductCardLink href를 위해 사용 */
  productId: string | null;
  thumbnail: VerticalProductCardThumbnailProps;
  metadata: VerticalProductCardMetadataProps;

  /** @default false */
  metadataPadding?: boolean;

  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onLikeClick?: (isLiked: boolean, event: React.MouseEvent<HTMLSpanElement>) => void;
}

const thumbnailLikeButtonCss = css`
  z-index: 1;
`;

export const VerticalProductCard = forwardRef<HTMLDivElement, VerticalProductCardProps>((props, ref) => {
  const {
    size = 'medium',
    soldOut,
    href,
    renderProductCardLink,
    productId,
    thumbnail,
    metadata,
    metadataPadding = false,
    onClick,
    onLikeClick,
  } = props;

  const item = getItemPropsByVerticalProductCardProps(props);

  return (
    <ProductCardRoot className='product-card' ref={ref} size={size} soldOut={soldOut}>
      <ProductCardLink
        className='product-card-link'
        item={item}
        href={href}
        renderProductCardLink={renderProductCardLink}
        onClick={onClick}
      />
      <Thumbnail
        className='product-card-thumbnail'
        size={size}
        imageUrl={thumbnail.imageUrl}
        webpImageUrl={thumbnail.webpImageUrl}
        jpegImageUrl={thumbnail.jpegImageUrl}
        overlayImageUrl={thumbnail.overlayImageUrl}
      >
        <ThumbnailRank className='product-card-thumbnail-rank' rank={thumbnail.rank} />
        <ThumbnailNudge className='product-card-thumbnail-nudge' nudgeBadgeList={thumbnail.nudgeBadgeList} />
        <ThumbnailEmblem
          className='product-card-thumbnail-emblem'
          size={size}
          emblemBadgeList={thumbnail.emblemBadgeList}
        />
        <ThumbnailLikeButton
          className='product-card-thumbnail-like-button'
          css={thumbnailLikeButtonCss}
          productId={productId}
          onLikeClick={onLikeClick}
        />
      </Thumbnail>
      <MetadataRoot className='product-card-metadata' size={size} padding={metadataPadding}>
        <MetadataTitle className='product-card-metadata-title' size={size} title={metadata.title} />
        <MetadataPrice
          className='product-card-metadata-price'
          salesStatus={metadata.salesStatus}
          price={metadata.price}
        />
        <MetadataOneDayDelivery
          className='product-card-metadata-one-day-delivery'
          oneDayDelivery={metadata.oneDayDelivery}
        />
        <MetadataPlpBadgeItems
          className='product-card-metadata-plp-badge-items'
          size={size}
          plpBadgeList={metadata.plpBadgeList}
        />
        <MetadataReviewWithFomo
          className='product-card-metadata-review-with-fomo'
          size={size}
          review={metadata.review}
          fomo={metadata.fomo}
        />
      </MetadataRoot>
    </ProductCardRoot>
  );
});
