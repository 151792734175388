import { Gray_Light_100, Gray_Light_600 } from '@croquiscom/zds';
import { css } from '@emotion/react';

export const onlyWebStyle = css`
  :root {
    --safe-area-top: env(safe-area-inset-top);
  }

  /* 토스 안전 영역을 위한 상단 패딩 */
  .safe-area-padding {
    height: var(--safe-area-top, env(safe-area-inset-top));
    background-color: #fff;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }

  #__next,
  #root {
    background: ${Gray_Light_100};
  }

  .review-icon {
    color: ${Gray_Light_600} !important;
  }
`;
